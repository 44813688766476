import { ref, reactive } from "vue";
import c3api from "../c3api";
import c3apiv2 from "../c3apiv2";
import { useRouter } from "vue-router/auto";
import { useToast } from "vue-toastification";
import { unwrapReactive } from "../utils/unwrapReactive";

export default function useInboundOrders() {
	const inboundLoadingCount = ref(0);
	const inboundOrders = ref(null);
	const inboundOrder = ref(null);
	const orderDocuments = ref(null);
	const orderHistory = ref(null);
	const inboundOrderPallets = ref(null);
	const inboundOrderTransactions = ref(null);
	const lineItems = ref(null);
	const toast = useToast();
	const defaultLocalState = reactive({});
	const router = useRouter();
	const uploadingDocuments = ref(false);

	const fetchInboundOrders = async (localState = defaultLocalState) => {
		inboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3apiv2.get("/inbound_orders", { params });
			inboundOrders.value = JSON.parse(response.data[0]).orders;
		} catch (error) {
			toast.error(error.response?.data?.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	const fetchInboundOrder = async (id, localState = defaultLocalState) => {
		inboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/inbound_orders/${id}`, { params });
			return (inboundOrder.value = response.data.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	const fetchInboundOrderDocuments = async (
		id,
		localState = defaultLocalState
	) => {
		inboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(
				`/inbound_orders/${id}/order_documents`,
				{ params }
			);
			return (orderDocuments.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	const uploadInboundOrderDocuments = async (id, formData) => {
    try {
      uploadingDocuments.value = true;
      const response = await c3api.post(`/inbound_orders/${id}/order_documents`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
      });
      toast.success("Documents uploaded successfully.");
      return response.data.data;
    } catch (error) {
			toast.error(error.response.data.data.join(". "));
    } finally {
      uploadingDocuments.value = false;
    }
  }

	const fetchInboundOrderLineItems = async (
		id,
		localState = defaultLocalState
	) => {
		inboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/inbound_orders/${id}/order_items`, {
				params,
			});
			return (lineItems.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	const fetchInboundOrderPallets = async (
		id,
		localState = defaultLocalState
	) => {
		inboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/inbound_orders/${id}/lps`, {
				params,
			});
			return (inboundOrderPallets.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	const fetchInboundOrderTransactions = async (
		id,
		localState = defaultLocalState
	) => {
		inboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(
				`/inbound_orders/${id}/inventory_transactions`,
				{ params }
			);
			return (inboundOrderTransactions.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	const fetchInboundOrderHistory = async (
		id,
		localState = defaultLocalState
	) => {
		inboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/inbound_orders/${id}/order_history`, {
				params,
			});
			return (orderHistory.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	const createInboundOrder = async (localState = defaultLocalState) => {
		inboundLoadingCount.value++;
		let params = unwrapReactive(localState);
		try {
			const response = await c3api.post("/inbound_orders", params);
			toast.success("InboundOrder created successfully!");
			router.push("/admin/inbound_orders");
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	const deleteInboundOrder = async (user) => {
		const confirmation = confirm(
			`Are you sure you want to delete user ${user.name}?`
		);
		if (confirmation) {
			try {
				inboundLoadingCount.value++;
				const response = await c3api.delete(`/inbound_orders/${user.id}`);
				toast.success(`InboundOrder ${user.name} deleted successfully`);
				return response.data;
			} catch (error) {
				console.error(error);
				toast.error(error.response.data.data.join(". "));
			} finally {
				inboundLoadingCount.value--;
			}
		}
	};

	const fetchInboundOrderAppointments = async (id) => {
		inboundLoadingCount.value++;
		try {
			const response = await c3api.get(`/inbound_orders/${id}/appt`);
			return response.data.data;
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	function updatePage(newPage) {
		router.push({ query: { page: newPage } });
	}

	const uploaderInfo = ref(null);

	const fetchUploaderInfo = async () => {
		inboundLoadingCount.value++;
		try {
			const response = await c3api.get("/inbound_orders/uploader");
			uploaderInfo.value = response.data; // Store the response data
			return response.data;
		} catch (error) {
			toast.error(error.response?.data?.data.join(". "));
		} finally {
			inboundLoadingCount.value--;
		}
	};

	return {
		inboundOrders,
		inboundOrder,
		lineItems,
		orderDocuments,
		orderHistory,
		inboundOrderPallets,
		inboundOrderTransactions,
		inboundLoadingCount,
		fetchInboundOrders,
		fetchInboundOrder,
		fetchInboundOrderDocuments,
		fetchInboundOrderLineItems,
		fetchInboundOrderHistory,
		fetchInboundOrderPallets,
		fetchInboundOrderTransactions,
		createInboundOrder,
		deleteInboundOrder,
		fetchInboundOrderAppointments,
		updatePage,
		uploadInboundOrderDocuments,
		uploadingDocuments,
		fetchUploaderInfo,
		uploaderInfo,
	};
}
